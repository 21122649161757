import React from 'react';
import VideoCard from './VideoCard';
import group19 from '../assets/homeWebp/heroSection/Group 19.webp';
import logo from '../assets/homeWebp/Startup_elevate.png';
import karnatakaflag from "../assets/Karnataka/Karnatakaflag.png"

function Hero({ setViewModal, videoModal, setvideoModal, videoOutput, setVideoOutput }) {
  const videoLinks = [
    {
      id: 1,
      name: "video1",
      title: "Why Our Courses?",
      link: 'https://www.youtube.com/embed/iN03xaML-tc'
    },
    {
      id: 2,
      name: "video2",
      title: "Why Microdegree?",
      link: 'https://www.youtube.com/embed/bUlgsAU_VGM'
    },
  ];

  const handleVideo1 = () => {
    console.log('hi');
    setVideoOutput(videoLinks.filter((item) => item.name.includes("video1"))[0]);
    console.log(videoOutput);
    setvideoModal(!videoModal);
  };

  const handleVideo2 = () => {
    setVideoOutput(videoLinks.filter((item) => item.name.includes("video2"))[0]);
    setvideoModal(!videoModal);
  };

  return (
    <div>
      {/* Large screens */}
      <div className='hidden 2xl:flex xl:flex w-full h-[650px] xl:h-[580px] px-10 md:px-14 xl:px-18 2xl:px-18 2xl:mt-6 xl:mt-10 items-center'>
        <div className="w-full h-full basis-1/2 flex items-center pb-10 xl:mt-0 2xl:mt-20 xl:mt-20">
          <div className="px-5 overflow-visible w-fit h-fit min-w-[380px] text-left space-y-9">
            <div className="space-y-7 min-w-[450px] max-w-[600px] 2xl:max-w-[600px]">
              <p className='text-3xl xl:text-4xl sm:text-[2.3rem] sm:leading-[2.4rem] 2xl:text-5xl leading-[2.4rem] 2xl:leading-[3.1rem] text-[#4159ec] tracking-tight'>
               <br></br>
                <span className='font-poppins w-full font-semibold tracking-tight xl:text-base md:text-md text-[#151595]'>Get Ready For</span> <a href='https://www.microdegree.work/'></a> <br></br> <span className='font-poppins font-semibold 2xl:text-[48px] '></span><span className='font-poppins font-semibold text-[#4159ec] 2xl:text-[48px] xl:text-[48px]'> ನಿಮ್ಮ Dream </span><span className='font-poppins font-semibold 2xl:text-[48px] xl:text-[48px]'> Tech Role <br></br></span><span className='font-poppins font-semibold 2xl:text-[48px] xl:text-[48px]'><span className='flex font-poppins font-semibold 2xl:text-[48px] py-1'>in 6 Weeks <img className='flex' src={karnatakaflag} alt="Karnataka Flag" class="h-12 mr-2"/></span></span>
              </p>
              <p className="tracking-tight font-semibold w-fit">
                <p className='text-[#151595] 2xl:text-[24px] xl:text-2xl tracking-tight font-poppins font-medium mb-2'>Skill by Building Projects</p>
                <p className='font-poppins tracking-tight 2xl:text-[20px] xl:text-xl text-[#151595] font-medium mb-1'><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">GenAI</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Automation</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-mediu m text-blue-700 ring-1 ring-inset ring-blue-700/10">AWS</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">DevOps</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Python</span></p>
                <p className='font-poppins tracking-tight 2xl:text-[20px] xl:text-xl text-[#151595] font-medium'><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Full-Stack</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Django</span> </p>
              </p>
              <div className='2xl:space-x-8 xl:space-x-4'>
                <a href="#section1"><button className="tracking-tight font-poppins 2xl:p-[0.6rem] 2xl:px-6 xl:p-[0.9rem] xl:px-6 bg-[#FF0049] text-white xl:text-md 2xl:text-lg rounded-md shadow-md shadow-red-600/50">Join 30,000+ Students <i class="fa-solid fa-angles-right"></i></button></a>
                <button onClick={()=>setViewModal('77')} className=" p-[0.9rem] px-9 text-base 2xl:text-lg rounded-md tracking-tight font-poppins font-medium 2xl:text-lg rounded-md 2xl:p-2 2xl:px-3 xl:p-[0.9rem] xl:px-4 border-2 border-[#151595] text-[#151595] xl:text-md rounded-md shadow-md shadow-blue-900/50 "><i class="fa-solid fa-phone-volume text-[#151595]"></i>&nbsp;&nbsp;&nbsp;Talk to our Career Counsellor</button>
              </div>
            </div>

            <div className="w-3/4 flex space-x-3 py-3 overflow-x-scroll h-[280px]">
            <VideoCard handleVideo={handleVideo2} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Untitled%20design_w3KEtGTzE.png?updatedAt=1718787992795" title="DD Chandana" loading="lazy"></VideoCard>
            <VideoCard handleVideo={handleVideo1} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Untitled%20design_urNsa9nGk.jpg?updatedAt=1718787992664" title="Josh Talks" loading="lazy"></VideoCard>
          </div>
          </div>
        </div>
        <div class="relative ml-20 mb-20 w-[500px] h-[650px]">
          <div class="absolute inset-0 flex flex-col justify-center items-center translate-x-0 translate-y-0">
            <br />
            
            <iframe width="498" height="280" src="https://www.youtube.com/embed/UAuq1mHYVuA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=1&controls=1&autoplay=1&loop=1&playlist=UAuq1mHYVuA" title="YouTube video player" frameborder="0" loading="lazy" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="rounded-xl shadow-xl"></iframe>
            <div class="py-2 text-4xl w-full sm:text-base 2xl:text-sm font-poppins tracking-tight font-semibold sm:tracking-[0.04em] space-x-2 text-[#5072A7] ">MicroDegree is proud winner of Government of
              <p className='flex text-4xl text-center items-center justify-center sm:text-base 2xl:text-sm font-poppins tracking-tight font-semibold  sm:tracking-[0.04em] space-x-2 text-[#5072A7] '> Karnataka Startup Elevate <img width="100" height="20" src={logo}></img></p>
            </div>
          </div>
        </div>
        
      </div>
      

      {/* Medium screens */}
      <div className='hidden md:flex lg:hidden mx-10 py-8 flex flex-col items-center justify-center pb-14'>
        <div className="text-left space-y-6 w-full h-[1200px]">
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-gray-700 text-[#4159ec]">

              <span className="block font-poppins tracking-tight font-semibold text-xl text-[#151595]">Get Ready For</span>
              <span className="block font-poppins tracking-tight font-semibold text-5xl text-[58px]">ನಿಮ್ಮ Dream Tech Role in <span className='flex'>6 Weeks <img src={karnatakaflag} alt="Karnataka Flag" class="h-8 mr-2"/></span></span>
            </h1>
            <p className="tracking-tight font-semibold w-fit">
                <p className='text-[#151595] text-[30px] tracking-tight font-poppins font-medium mb-2'>Skill by Building Projects</p>
                <p className='font-poppins tracking-tight text-[30px] text-[#151595] font-medium mb-1'><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">GenAI</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Automation</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">AWS</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">DevOps</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Python</span></p>
                <p className='font-poppins tracking-tight text-[30px] text-[#151595] font-medium'><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Full-Stack</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Django</span></p>
              </p>
            <div className="relative w-full h-[540px] ">
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="relative w-full pb-[56.25%] h-0">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-xl shadow-md"
                    src="https://www.youtube.com/embed/UAuq1mHYVuA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=1&controls=1&autoplay=1&loop=1&playlist=UAuq1mHYVuA"
                    title="YouTube video player"
                    frameBorder="0"
                    loading="lazy"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="text-center mt-2 max-w-full text-[#5072A7] font-semibold font-poppins tracking-tight">
                  <p>MicroDegree is proud winner of Government of Karnataka Startup Elevate <img className="inline h-7 align-middle" src={logo} alt="Logo" /></p>
                  
                </div>
              </div>
            </div>

            <div className='hidden sm:flex justify-center space-x-4'>
  <a href="#section1">
    <button className="tracking-tight font-poppins p-[0.6rem]  xl:p-[0.9rem] xl:px-[60px] md:p-[1rem] md:px-[50px] bg-[#FF0049] text-white text-base xl:text-lg 2xl:text-lg md:text-lg rounded-md shadow-md shadow-red-600/50">
      Join 30,000+ Students <i class="fa-solid fa-angles-right"></i>
    </button>
  </a>
  
    <button onClick={()=>setViewModal('77')} className=" p-[0.9rem]  text-[#151595] text-lg 2xl:text-lg rounded-md tracking-tight font-poppins font-medium rounded-md border-2 border-[#151595] xl:p-[0.9rem] xl:px-[30px] md:p-[rem] xl:text-lg md:text-lg text-sm shadow-md shadow-blue-900/50">
      <i class="fa-solid fa-phone-volume text-[#151595] "></i>&nbsp;&nbsp;&nbsp;Talk to our Career Counsellor
    </button>
  
</div>
          </div>

          <div className="w-full flex space-x-3 py-3 overflow-x-scroll h-[280px]">
            <VideoCard handleVideo={handleVideo2} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/WhatsApp%20Image%202024-05-21%20at%203.54.58%20PM_C3NDrHNsk.jpeg?updatedAt=1716287893111" title="Why Microdegree?" loading="lazy"></VideoCard>
            <VideoCard handleVideo={handleVideo1} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/WhatsApp%20Image%202024-05-21%20at%203.54.59%20PM_SMEAUKQaC.jpeg?updatedAt=1716287893062" title="Why Our Courses?" loading="lazy"></VideoCard>
          </div>
        </div>
      </div>


 {/* lg screens */}
 <div className='hidden 2xl:hidden xl:hidden lg:flex mx-20 py-8 flex flex-col items-center justify-center pb-14'>
        <div className="text-left space-y-6 w-full h-[1200px]">
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-gray-700 text-[#4159ec]">
              <span className="block font-poppins tracking-tight font-semibold text-xl text-[#151595]">Get Ready For</span>
              <span className="block font-poppins tracking-tight font-semibold text-6xl">ನಿಮ್ಮ Dream Tech Role in <span className='flex'>6 Weeks <img src={karnatakaflag} alt="Karnataka Flag" class="h-8 mr-2"/></span></span>
            </h1>
            <p className="tracking-tight font-semibold w-fit">
                <p className='text-[#151595] text-4xl  tracking-tight font-poppins font-medium mb-2'>Skill by Building Projects</p>
                <p className='font-poppins tracking-tight text-3xl text-[#151595] font-medium mb-1'><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">GenAI</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Automation</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">AWS</span>  <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">DevOps</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Python</span></p>
                <p className='font-poppins tracking-tight text-3xl text-[#151595] font-medium '><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Full-Stack</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Django</span>  </p>
              </p>
            <div className="relative w-full h-[600px] ">
              <div className="absolute inset-20 flex flex-col items-center justify-center">
                <div className="relative w-full pb-[56.25%] h-0">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-xl shadow-md"
                    src="https://www.youtube.com/embed/UAuq1mHYVuA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=1&controls=1&autoplay=1&loop=1&playlist=UAuq1mHYVuA"
                    title="YouTube video player"
                    frameBorder="0"
                    loading="lazy"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="text-center text-2xl mt-2 max-w-full text-[#5072A7] font-semibold font-poppins tracking-tight">
                  <p>MicroDegree is proud winner of Government of Karnataka Startup Elevate <img className="inline h-7 align-middle" src={logo} alt="Logo" /></p>
                  
                </div>
              </div>
            </div>

            <div className='hidden sm:flex justify-center space-x-4'>
  <a href="#section1">
    <button className="tracking-tight font-poppins p-[0.6rem]  xl:p-[0.9rem] xl:px-[60px] md:p-[1rem] md:px-[50px] bg-[#FF0049] text-white text-lg xl:text-lg 2xl:text-lg md:text-lg rounded-md shadow-md shadow-red-600/50">
      Join 30,000+ Students <i class="fa-solid fa-angles-right"></i>
    </button>
  </a>
 
    <button onClick={()=>setViewModal('77')} className=" p-[0.9rem] text-lg 2xl:text-lg rounded-md tracking-tight font-poppins font-medium rounded-md xl:p-[0.9rem] xl:px-[30px] md:p-[1rem] md:px-[50px]  border-2 border-[#151595] text-[#151595] xl:text-lg md:text-lg text-lg shadow-md shadow-blue-900/50">
      <i class="fa-solid fa-phone-volume text-[#151595] "></i>&nbsp;&nbsp;&nbsp;Talk to our Career Counsellor
    </button>

</div>

          </div>

          <div className="w-full flex space-x-3 py-3 overflow-x-scroll h-[280px]">
            <VideoCard handleVideo={handleVideo2} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/WhatsApp%20Image%202024-05-21%20at%203.54.58%20PM_C3NDrHNsk.jpeg?updatedAt=1716287893111" title="Why Microdegree?" loading="lazy"></VideoCard>
            <VideoCard handleVideo={handleVideo1} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/WhatsApp%20Image%202024-05-21%20at%203.54.59%20PM_SMEAUKQaC.jpeg?updatedAt=1716287893062" title="Why Our Courses?" loading="lazy"></VideoCard>
          </div>
        </div>
      </div>
      

      {/* Small screens */}
      <div className="sm:hidden mx-5 py-8 flex flex-col items-center justify-center pb-0">
        <div className="text-left space-y-6 w-full">
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-gray-700 text-[#4159ec]">
              <span className="block font-poppins tracking-tight font-semibold text-sm text-[#151595]">Get Ready For</span>
              <span className="block font-poppins tracking-tight font-semibold text-[36px] ">ನಿಮ್ಮ Dream Tech<span></span><span className='flex mt-1'>Role in 6 Weeks<img src={karnatakaflag} alt="Karnataka Flag" class="h-8 mr-2"/></span> </span>
            </h1>
            <p className="text-sm font-poppins tracking-tight font-semibold">
              <span className="text-[#151595] text-xl block mb-2">Skill by Building Projects</span>
              <span className="text-[#151595] text-[15px] block mb-1"><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">GenAI</span>  <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Automation</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">AWS</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">DevOps</span></span>
              <span className="text-[#151595] text-[15px] block"><span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Python</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Full-Stack</span> <span class="inline-flex items-center rounded-2xl bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Django</span></span>
            </p>
            <div className="relative w-full h-[282px]">
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="relative w-full pb-[56.25%] h-0">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-xl shadow-md"
                    src="https://www.youtube.com/embed/UAuq1mHYVuA?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=1&controls=1&autoplay=1&loop=1&playlist=UAuq1mHYVuA"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="text-center mt-2 mb-2 max-w-[345px] text-[#5072A7] font-semibold font-poppins tracking-tight">
                  <p className='text-sm'>MicroDegree is proud winner of Government of Karnataka Startup Elevate <img className="inline h-5 align-middle" src={logo} alt="Logo" /></p>
                  
                </div>
              </div>
            </div>

            <div className="space-y-5">
              <a href="#section1"><button className="font-semibold font-poppins tracking-tight p-3 py-3 px-6 w-full bg-[#ff0055] text-white text-lg rounded-md shadow-md shadow-red-600/50">Join 30,000+ Students <i class="fa-solid fa-angles-right"></i></button></a>
              
              <button onClick={()=>setViewModal('77')} className=" p-[0.9rem] px-1 text-base 2xl:text-lg rounded-md font-semibold font-poppins tracking-tight rounded-md p-2 py-3 w-full border border-[#151595] text-[#151595] text-lg shadow-md shadow-blue-900/50"><i className="fa-solid fa-phone-volume text-[#151595] "></i>&nbsp;&nbsp;&nbsp;Talk to our Career Counsellor</button>
            </div>
          </div>

          <div className="w-full flex space-x-3 py-3 overflow-x-scroll">
            <VideoCard handleVideo={handleVideo2} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Untitled%20design_w3KEtGTzE.png?updatedAt=1718787992795" title="Why Microdegree?" loading="lazy"></VideoCard>
            <VideoCard handleVideo={handleVideo1} vidthumb="https://ik.imagekit.io/microdegree/Main%20Website%20Videos/Untitled%20design_urNsa9nGk.jpg?updatedAt=1718787992664" title="Why Our Courses?" loading="lazy"></VideoCard>
          </div>
        </div>
      </div>


      
    </div>
  );
}

export default Hero;
